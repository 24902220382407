.program-card{
  border-left: 2px solid #85B6FF !important;

  .card-box:hover{
    height: 150px;
    max-width: 150px;
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 15px;

    .card-body{
      //background-color: #dc3545;

    }

  }
}