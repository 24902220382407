.stepper-container{
    background: #1DA81D0F;
    border:1px solid #E3E3E3;
    border-bottom: 0;
}
.stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 5px;

.stepper-row {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    .step {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        background-color: white;
        border-radius:50%;
        margin:8px;
    }
    .current{
        outline-offset: 1px;
        outline:3px ridge #6dbaf7   ;
    }
    .answered{
        background-color: rgb(52, 152, 219);
        color: #fff;
    }
    .notAnswered {
        background-color: #ffa337;
        color: #fff;
    }
    .bookMark
    {
        background-color: #9370DB;
        color: #fff;
    }
    .correct {
        background-color:#41cb417d;
        color: #fff;
    }

    .incorrect {
        background-color: #c54219b0;
        color: #fff;
    }
}

}

.Question-header{
    background-color: rgb(29, 168, 29);
    padding: 3px 10px;
    color: white;
    text-align: center;
    border-bottom: 3px solid rgba(0, 75, 0, 0.55);
}

.stepper {
    .stepper-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .stepper-row {
            display: flex;
            margin-bottom: 10px;

            .step {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
                border-radius: 50%;
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;

                // Default background for unattempted questions (no status)
                background-color: #f0f0f0;
                border: 1px solid #ccc;

                &.skipped {
                    background-color: #ffc107; // Yellow for skipped
                    border: 1px solid #ffc107;
                    color: #fff;
                }

                &.correct {
                    background-color: #3bb857; // Green for correct
                    border: 1px solid #3bb857;
                    color: #fff;
                }

                &.wrong {
                    background-color: #e9626f; // Red for wrong
                    border: 1px solid #e9626f;
                    color: #fff;
                }

                &.current {
                    //border: 3px solid #007bff; // Blue border for the current step
                }

                .radio-number {
                    display: inline-block;
                    font-size: 18px;
                }
            }
        }
    }
}

.indicatorContainer{
    .Correct{
        background-color: #3bb857;
    }
    .Wrong{
        background-color: #e9626f;
    }
    .Skipped{
        background-color: #f0f0f0;
    }
}