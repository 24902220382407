@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.loginScreen_wrapper {
  background-color: #fff;
  height: 100%;

  .right-side_tc {
    background: #004e82;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-side_st {
    background: #fffaf4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 576px) {
    .right-side_st img {
      display: none;
    }
  }

  .login-wrap {
    &.tc_wrap {
      margin-top: 10rem;
    }

    margin-top: 6rem;
    @media (max-width: 576px) {
      margin-top: 0;
    }

    .form-card {
      @media (max-width: 576px) {
        margin: 40px 0px 0px 0px;
        box-shadow: none;
      }
      margin: 10px 0 10px 60px;
      padding: 20px;
      border: 1px solid #f7f7f7;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05),
      0 1px 2px rgba(0, 0, 0, 0.07),
      0 3px 6px rgba(0, 0, 0, 0.06),
      0 10px 20px rgba(0, 0, 0, 0.05),
      0 30px 60px rgba(0, 0, 0, 0.03);
    }

    .app-routes {
      width: 600px;
      @media (max-width: 576px) {
        width: unset;

      }

      a {
        text-decoration: none;
      }

      .loginBtn {
        .btn-otp {
          background-color: #27AE60;
          padding: 10px 20px;
          border-radius: 5px;
        }
      }

      .text-blue {
        color: #3498DB;
      }

      .border-blue {
        border-color: #3498DB;
      }
    }

    .login_info_wrap {
      margin-top: 2rem;
      @media (max-width: 576px) {
        margin-top: 1rem;
      }
    }

    .loginAs {
      margin: 15px 0;
      padding: 13px 15px;
      border: 1px solid #C8C8C8;
      border-radius: 10px;

      h6 {
        color: black;
        font-weight: 600;
        //text-decoration: none;
      }
    }
  }
}