.image-options {
    position: relative;
}

.cross-icon {
    position: absolute;
    right: 2px;
    background-color: transparent;
    color: red;
    font-size: 18px;
}

.option-Image {
    width: 300px;
    height: 220px;
    max-width: 500px;
    max-height: 220px;
}

@media (max-width: 768px) {
    .option-Image {
        max-width: 100%;
        max-height: 80px;
    }
}
