@media (max-width: 576px) {
  .offcanvas-body .instruction-wrap {
    padding: 0 !important;
  }
  .offcanvas-body .instruction_title, .offcanvas-body .instruction_body {
    padding: 0 !important;
  }
}

.instruction-wrap {
  @media (max-width: 576px) {
    //padding: 0 !important;
    .offcanvas-body .instruction_title, .offcanvas-body .instruction_body {
      padding: 0 !important;
    }
  }

  .instruction-container {
    margin: 0 auto;
    padding: 20px;
  }

  p {
    font-size: 14px;
    color: #423b3b;
  }

  .question-table {
    max-width: 600px;
    border: 1px solid #EAEAEA;

    th {
      font-size: 14px;
      font-weight: 600;
      border-right: 1px solid #EAEAEA;
    }
  }

  td {
    border-right: 1px solid #EAEAEA;
  }

  .status-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 0;
  }

  .status-title {
    font-size: 14px;
  }

  .done {
    background-color: #3498DB;
  }

  .markForReview {
    background-color: #9400d3;
  }

  .pending {
    background-color: #FFA337;
  }

  .unanswered {
    background-color: #ccc;
  }

  .center-content {
    text-align: center;
  }

  .sticky-bottom-btns {
    text-align: end;
  }

  .btnGreen,.btnGreen:hover {
    background-color: #27ae60;
    padding: 10px 30px;
  }

  @media (max-width: 576px) {
    .margns {
      margin-bottom: 60px;
    }
    .sticky-bottom-btns {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 9;
      box-shadow: 3px 3px 5px 6px #ccc;
      padding: 15px 0;

      .btn {
        width: 50% !important;
      }
    }
  }
}

