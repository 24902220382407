.upload_avatar{
  display: flex;
  align-items: center;
  @media (max-width: 935px) {
    display: block;
  }
  .image_dimes{
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .initials_avatar{
    margin-left: 30px;
    & p{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      font-size: 50px;
      text-align: center;
      line-height: 150px;
      background-color: #3498DB;
      color: white;
      @media (max-width: 935px) {
        width: 80px;
        height: 80px;
        font-size: 30px;
        line-height: 80px;
        margin-bottom: 20px !important;
      }
    }
  }
}