.course_detail {
  .page-title-area.item-bg1 {
    background-image: url(../../../../../assets/images/1.jpg);
    background-blend-mode: overlay;
    background-color: #00000057;
  }

  .page-title-area {
    position: relative;
    z-index: 1;
    background-color: #ff1949;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 90px;
    @media (max-width: 576px) {
      padding-top: 140px;
      padding-left: 30px;
    }

    ul {
      padding-left: 0;
      margin-bottom: 12px;
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: 25px;
        position: relative;
        color: #ffffff;

        a {
          display: inline-block;
          color: #ffffff;
          opacity: 0.85;
        }

        &::before {
          content: '\F280';
          font-family: "bootstrap-icons";
          position: absolute;
          right: -25px;
          top: 0;
          opacity: .85;
          margin-right: 7px;
        }

        &:last-child {
          margin-right: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    h3 {
      color: #ffffff;
      margin-bottom: 0;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .course_detail_area {
    .course_detail_header {
      .courses-title {
        p {
          color: #727695;
          font-size: 15px;
        }
      }

      .courses-meta ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          @media (max-width: 576px) {
            margin-bottom: 15px;
          }
          margin-right: 20px;
          position: relative;
          display: inline-block;
          border-right: 1px solid #eeeeee;
          font-weight: 500;
          font-size: 16px;
          padding-right: 20px;
          padding-left: 37px;

          &:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
          }

          a {
            display: inline-block;
            color: #252525;
          }

          span {
            display: block;
            color: #3498DB;
            text-transform: uppercase;
            margin-bottom: 1px;
            font-size: 14px;
            font-weight: 600;
          }

          i {
            font-size: 25px;
            color: #cfcfcf;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        @media (max-width: 576px) {
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 15px;
          row-gap: 15px;
          -webkit-column-gap: 15px;
          -moz-column-gap: 15px;
          grid-column-gap: 15px;
          column-gap: 15px;
          display: grid;
        }
      }

      .courses-title h2 {
        margin-bottom: 12px;
        font-size: 29px;
        font-weight: 700;
      }

      .courses-price {
        .price_wrap {
          display: flex;
          justify-content: end;
          label{
            cursor: pointer;
          }
          @media (max-width: 576px) {
            justify-content: start;
          }
        }

        .mob_btn {
          display: none;
        }

        @media (max-width: 576px) {
          .mob_btn {
            display: block;
          }
          display: flex;
          justify-content: space-between;
          text-align: left;
          margin-top: 20px;
        }

        .courses-review {

          @media (max-width: 576px) {
            font-size: 17px;
            display: block;
            margin-bottom: 10px;
          }

          svg {
            height: 25px;
            @media (max-width: 576px) {
              height: 30px;
            }
          }
        }

        text-align: right;

        .price {
          @media (max-width: 576px) {
            font-size: 30px;
          }
          display: inline-block;
          margin-right: 15px;
          position: relative;
          top: 6px;
          font-size: 35px;
          font-weight: 600;
        }
      }
    }

    .courses-details-image {
      border-radius: 5px;

      img {
        width: 100%;
        height: 430px;
        border-radius: 5px;
        @media (max-width: 576px) {
          height: 250px;
        }
      }
    }

    .applyCoupon {
      margin-bottom: 30px;
      background-color: #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
      @media (max-width: 576px) {
        margin-top: 20px;
      }
    }
  }
  .btn-outline-blue{
    border-color: #3498DB;
    color: #3498DB;
  }
}

.tabTextColors {
  color: rgb(158, 158, 158);
}

.studentTab {
  cursor: pointer;
  padding: 12px 25px;
}

.tabButtons {
  background-color: #e9ecef;
  border: none;
  border-bottom: 2px solid transparent;
  color: #3498DB;
  border-bottom-color: #3498DB;
  cursor: pointer;
  font-weight: 600;
}

.coupon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 7px;
}

.image {
  width: 50%;
}

.image img {
  width: 100%;
  height: 300px;
}

.courseCoupon {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 3px;
}


.code {
  text-align: center;
  color: #3498DB;
}

.couponList {
  margin-top: 5px;
  max-height: 120px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
  //width: 325px;
  //background-color: whitesmoke;
}

.singleCoupon {
  //display: flex;
  //justify-content: space-around;
  padding: 5px;
  margin: 5px;
  background-color: white;
}

.default-btn {
  transition: 0.5s;
  display: inline-block;
  padding: 10px 25px;
  position: relative;
  background-color: #ff1949;
  color: #ffffff;
  border: 2px solid #ff1949;
  border-radius: 1px;
  font-size: 14.5px;
  font-weight: 700;
}

.butn,
.butn:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14.5px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #ff1949;
  padding: 10px 25px;
  position: relative;
  margin-right: 13px;
  border: none;
  color: #fff;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.butn::before {
  background: #fff;
  content: "";
  height: 155px;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.butn::after {
  background: #fff;
  content: "";
  height: 20rem;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 8rem;
}

.butn__new::before {
  left: -50%;
}

.butn__new::after {
  left: -100%;
}

.butn:hover,
.butn:active {
  transform: translateY(-3px);
  color: #fff;
  background-color: #ff1949;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.butn__new:hover::before {
  left: 120%;
  opacity: 0.5;
}

.butn__new:hover::after {
  left: 200%;
  opacity: 0.6;
}

.butn span {
  z-index: 20;
}
