.course-card {
  .card-box {
    height: 150px;
    max-width: 350px;
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 15px;

    .card-body {
      p {
        font-size: 15px;
        color: #a1a7ae
      }
    }

  }
  .text-ellipsis{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}