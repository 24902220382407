.subject-card{
  .card-box{
    height: 150px;
    max-width: 350px;
    position: relative;
    overflow: hidden;
    display: block;
    //border:none;
    border-radius: 15px;

    .card-body{
      //p{
      //    font-weight: bold;
      //    font-size: 25px;
      //}
      //p{
      //  font-size: 15px;
      //  color: #a1a7ae
      //}

    }

  }
}