@import "../../../assets/css/style.scss";


@media (max-width: 576px) {
  .sidebarCollapsed .navbar-menu {
    width: 0 !important;
    display: none !important;
  }

  .navbar-brand-box .dialogCloseButton {
    position: absolute !important;
    margin-right: 20px;
    top: 10px;
    right: 0;
    pointer-events: auto !important;
  }
}

@media (min-width: 576px) {
  .dialogCloseButton {
    display: none !important;
  }

}

.sidebarCollapsed {
  .navbar-menu {
    .navbar-brand-box {
      margin-top: 0px !important;
      padding: 0;
    }

    width: 100px;

    .nav-link {
      .submenu {
        ul {
          margin-left: 0 !important;
        }

        span {
          display: block;
        }
      }

      span {
        display: none;
      }
    }
  }

}

.initials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3498DB;
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 38px;

}

.sidebarCollapsed .navbar-menu .navbar-content-wrapper .menuItem .submenu .menuItem > .nav-link {

  margin-left: 0;
}

.navbar-menu {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  //pointer-events: none;
  z-index: 99;
  color: #000 !important;
  background: #fff !important;
  box-shadow: 0 8px 24px rgb(229 228 230 / 40%);
  transition: left 0.2s ease, width 0.2s ease;
  border-right: 1px solid #eaedf1;

  .navbar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    max-width: 100%;
    -ms-overflow-style: none;
    max-height: calc(100vh - 0rem);
    overflow-y: auto;

    .menuItem {
      > button {
        &.active {
          &::after {
            transform: rotate(90deg);
          }
        }
      }

      > .nav-link {
        &.active {
          background-color: #F8F9FA;

          &::before {
            content: '';
            right: 0;
            height: 100%;
            width: 5px;
            position: absolute;
            background-color: #3498DB;
          }
        }
      }

      .submenu {
        background-color: #F8F9FA;

        .menuItem {
          > .nav-link {
            &.active {
              color: #3498DB;
            }

            margin-left: 20px;

          }
        }
      }
    }
  }

  .nav-link, .navLink {
    display: -webkit-box;
    display: -ms-flexbox;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #919191;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
  }

  .nav-link:hover {
    color: black;
  }

}

.navbar-brand-box {
  padding: 0 1.3rem;
  text-align: center;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;

  .logo {
    line-height: 70px;
  }
}

.nav-link.active {
  color: #3498DB;
}

.navLink {
  text-decoration: none;

  &.active {
    color: #3498DB;
  }
}

.arrow_icon::after {
  width: 1.25em;
  line-height: 0;
  content: url(https://s3.carvia.tech/dl/user-profiles/79f1d-9a1d384.svg);
  transition: transform .35s ease;
  transform-origin: 0.5em 50%;
  right: 15px;
  position: absolute;
}