.admin_mainWrap {
  h6 {
    font-size: 14px;
    color: #838383;
    margin-bottom: 2px !important;
  }

  p {
    font-size: 22px;
    font-weight: 700;
    color: #3498DB;
    margin-bottom: 0;
  }
  @media (max-width: 1199px) {
    .top_head_admin{
      .row{
        .col-xl-2{
          margin-bottom: 20px;
        }
      }
    }
  }
  .icon_wrap {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    color: white;
    text-align: center;
    @media (max-width: 1199px) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      i{
        font-size: 15px;

      }
    }
    &.tq_color {
      background-color: #4d44b5;
    }

    &.ta_color {
      background-color: #fb7d5b;
    }

    &.tc_color {
      background-color: #0F9D57;
    }

    &.tw_color {
      background-color: #fcc43e;
    }

    &.tl_color {
      background-color: rgb(239, 72, 63);
    }

    &.tas_color {
      background-color: darkcyan;
    }

    i {
      font-size: 20px;
      color: white;
    }
  }
  .accordion-header.card-header{
    padding: 0 !important;
  }
  .card.top_header {
    .card-header {

      border-color: #e6e6e6;
      position: relative;
      background: transparent;
      padding: 15px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

    }
  }

  .accordion-item button {
    padding: 10px 15px;
  }
}
