.App {
  /*text-align: center;*/
}
//body,h1,h2,h3,h4,h5,h6,p,span,b,strong,input {
//  font-family: 'Open Sans', sans-serif !important;
//  margin: 0 !important;
//}
body { margin: 0; font-family: 'Montserrat', sans-serif;}

h1,h2,h3,h4,h5,h5,b,strong {
  font-family: 'Montserrat', sans-serif;
}

p, a, span {
  font-family: 'Poppins', sans-serif;
}
.btn-cancel {
  background-color: #ececf1 !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
