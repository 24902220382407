.main_content_wrap {
  margin-left: 300px;
  margin-bottom: 0 !important;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  background-color: #3498db17;
  min-height: 100vh;
  height: 100%;
}
.collapsed_main_wrap{
  margin-left: 0;
  margin-bottom: 0 !important;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  background-color: #3498db17;
  min-height: 100vh;
  height: 100%;
}
@media (max-width: 576px) {
  .collapsed_main_wrap{
    margin-left: 0;
  }
  .main_content_wrap{
    margin-left: 0;
  }
}