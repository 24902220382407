.addQuestionButton {
    border: 1px solid rgb(25,135,84);
    outline: rgb(25,135,84);
    cursor: pointer;
    color:rgb(25,135,84);
    padding: 6px;
}

.submitButton {
    border: 1px solid rgb(25,135,84);
    outline: rgb(25,135,84);
    cursor: pointer;
    color:rgb(25,135,84);
    padding: 6px;
}

.cursorPointer {
    cursor: pointer;
}

.btnDisabled {
    cursor: not-allowed;
}
.dropdown-toggle,.react-dropdown-select {
    color: #000 !important;
}
.react-dropdown-select-dropdown{
    font-size: 15px;
}
.react-dropdown-select-dropdown > span{
    border-bottom: 1px solid #e6e6e6;
}
.react-dropdown-select-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.react-dropdown-select-option{
    margin: 0;
    font-size: 13px;
    padding: 1px 10px;
}

.icon-circle {
    display: inline-block;
    width: 22px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes it a circle */
    border: 2px solid #28a745; /* Green border color */
    text-align: center;
    line-height: 20px; /* Centers the icon vertically */
    background-color: #28a745;
}