.question_wrap {
  .questionLabel {
    border: 2px solid #dbdbdb;
    border-radius: 3px;
    margin: 4px;
  }

  .btnContainer {
    margin-top: 10px;
  }

  .choice-box {
    transform: scale(1.5);
    margin-left: 10px;
    background-color: #F4F4F4;
  }

  .btnGreen {
    background-color: #27ae60;
    padding: 10px 30px;
    color: white;
  }

  @media (max-width: 576px) {
    .questionBtn {
      margin-top: 0 !important;
    }
    .progress_bar {
      width: 60px !important;
      height: 60px !important;
    }
    .sticky-bottom-btns {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 9;
      box-shadow: 3px 3px 5px 6px #ccc;
      padding: 15px 0;
    }
  }

  .bookmarkIcon {
    color: #4000c9a3;
  }

  .likeIcon {
    color: red;
  }

  #validateBtn {
    background: #F39C12;
    border-radius: 5px;
    color: white;
    padding: 8px 23px;
    font-size: 14px;
    z-index: 1000;
  }

  #faded {
    background: rgba(243, 156, 18, 0.6);
    pointer-events: none;
    cursor: not-allowed;
    border-radius: 5px;
    padding: 8px 23px;
    font-size: 14px;
  }

  .explanation-header {
    cursor: pointer;
    color: #5E5E5E;
    font-weight: bold;
    padding: 7px 0px;
  }

  .explanation-box {
    width: 100%;
    border: 1px solid #5e5e5e45;
    background: white;
    padding: 12px 16px;
    color: black;
    border-radius: 3px;

  }

  .one-word {
    border: 1px solid #EDEDED;
    outline: none;
    width: 100%;
    border-radius: 3px;

  }

  @media (min-width: 576px) {
    .instruction_icon {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .btnContainer {
      margin-top: 0;

      .submitBtn {
        height: auto;
        font-weight: 500;
      }
    }
    .questionBtn {
      min-width: 100%;
      justify-content: space-between;
    }
    //.choice_dropdown {
    //  display: none;
    //}
  }
}
.questionContainer{
  @media (max-width: 576px) {
    margin-bottom: 100px;
  }

}
.mb-6{
  margin-bottom: 5rem;
}
.no-caret .dropdown-toggle::after {
  display: none;
}

.CircularProgressbar-text {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  dominant-baseline: middle;
  text-anchor: middle;
}
