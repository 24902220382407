.compstepper {
    display: flex;
    /*flex-direction: row!important; !* Align items horizontally *!*/
    align-items: start; /* Align items to the start */
    justify-content: start; /* Justify content to the start */
    gap: 10px;
    background-color: white!important;
}

.compstep {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
}

.compstep.compselected {
    /*background-color: #007bff !important;*/
    color: #fff;
}

.compstep.compcurrent {
    color: #fff;
    background-color: #3498DB !important;
}


@keyframes wobble {
    0% { transform: translateX(0%); }
    15% { transform: translateX(-10%); }
    30% { transform: translateX(10%); }
    45% { transform: translateX(-10%); }
    60% { transform: translateX(10%); }
    75% { transform: translateX(-10%); }
    100% { transform: translateX(0%); }
}

.wobble {
    animation: wobble 0.8s ease infinite;
}
