
  .submitBtn
  {
    width: 100%;
    //height: 50px;
    font-size: 18px;
    border-radius: 4px;
    background-color: #27ae60;
    color: white;
    border: none;
  }
  .submitBtn.active {
    background-color: #27ae60;
    color: white;
  }

