.phoneButton {
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
}

.userIdButton {
    border: none;
    background-color: white;
    color: black;
    cursor: pointer;
}

.loginDiv {
    min-width: 30%;
    margin: auto;
}

.methodButton {
    padding: 8px 16px;
    font-size: 16px;
    color: #333;
    border: none;
    border-right: 1px solid #ccc;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
}

.phoneButton,
.userIdButton {
    padding: 8px 16px;
    font-size: 16px;
    color: #333;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: color 0.3s, border-color 0.3s;
}


.button-label {
    margin: 0;
}

.wrapper{
    position: relative;
    //min-height: 100vh;
    img{
        max-height: 400px;
    }
    @media (max-width: 576px) {
        width: 100% !important;
    }
    .btn-register{
        cursor: pointer;
        background: #27AE60 !important;
        border: 1px solid #27AE60 !important;
        border-radius: 5px;
        padding: 12px 15px !important;
        font-weight: 700;
        color: #FFF;
        height: 100% !important;
    }
}
.loginScreen_wrapper{
    .login_head{
        display: flex;
        justify-content: space-between;
        .right_c{
            display: flex;
            justify-content: end;
            align-items: center;
        }
    }
    @media (max-width: 576px) {
        .login_head{
            display: block;
        }
        .right_c{
            h6{
                font-size: 13px;
            }
            font-size: 13px;
            justify-content: start !important;
        }
    }
}
.register_screen{
    .top-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 576px) {
            display: block;
        }
    }
    @media (max-width: 576px) {
        .login_info_wrap img{
            display: none;
        }
    }
    .login_info_wrap{
        margin-top: 0 !important;
    }
    .register_form{
        .btn-register{
            width: 100%;
            background: #27AE60 !important;
            border: 1px solid #27AE60 !important;
            border-radius: 5px;
            padding: 12px 15px !important;
            font-weight: 700;
            color: #FFF;
            height: 100% !important;
        }
        label{
            margin-bottom: 10px;
            width: 100%;
            color: #685F78;
            font-weight: 500;
        }
        .form-control{
            border-color: #e9ecef;
            border-radius: 5px;
            min-height: 50px;
            &:focus{
                box-shadow: none;
                border: 1px solid #d3d3d3 ;
            }
        }
    }
}
