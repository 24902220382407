form {
  font-weight: 600;
  font-size: 14px;

  .form-check-input {
    margin-right: 10px !important;
    font-size: 18px;
    border-color: #bcbcbc;
    &:focus{
      border-color: #29ba74;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(41, 186, 116, 0.25);
    }

    &:checked {
      background-color: #29ba74;
      border-color: #29ba74;
    }
  }
}
