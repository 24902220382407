.bulk_upload{
  .drop_box {
    border: 1px dashed #afacac;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    -webkit-transition: border-color .3s;
    transition: border-color .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: relative;
    padding: 15px 15px;
  }

  .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
  }

  .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
  }

  .btn {
    text-decoration: none;
    background-color: #FB6600FF;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
  }

  .btn:hover{
    text-decoration: none;
    background-color: #ffffff;
    color: #FB6600FF;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
  }
  .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
  }
}
