.main {
    width: 80%;
}
.patch_question{
    cursor: pointer;
    .number {
        height: 22px;
        width: 22px;
        line-height: 12px;
        background-color: #d63031;
        border-radius: 20px;
        color: white;
        text-align: center;
        position: absolute;
        top: -10px;
        right: -5px;
        padding: 3px;
        border-style: solid;
        border-width: 2px;
        font-size: 11px;
    }
    .patch_icn {
        font-size: 24pt;
        padding-bottom: 10px;
        color: black;
        margin-right: 40px;
        margin-left: 40px;
    }
}

.searchBar .formFeedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.4rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.searchBar {
    width: 25rem;
    padding: 3px 5px;

    button {
        color: #898989;
        font-size: 17px;
        height: 1rem;
        left: 0.625rem;
        margin-left: 10px;
        position: absolute;
        transform: translateY(-50%);
        width: 1rem;
        line-height: 1;
        margin-top: 1px;
        right: 20px;
        top: 50%;
    }
    .formControl {
        line-height: 1;
        font-size: 14px;
        border-radius: 5px;
        padding: 13px 20px;
        background: #e3ecf2;
        padding-top: 14px;
        padding-bottom: 14px;
        border: 0;
        &:hover {
            box-shadow: none;
        }
    }
}
.questionImage{
    width:50px;
    height: 40px;

}

.generalButton {
    width:auto;
    height: 40px;
    outline: none;
    color:#ffffff ;
    background-color: #3498DB;
    border:2px solid #3498DB;
    font-size: 18px;
    font-weight: 500;
}

.compButtonEnabled {
    width:auto;
    height: 40px;
    outline: none;
    color:#ffffff ;
    background-color: gray;
    border:2px solid gray;
    font-size: 16px;
    font-weight: 500;
}

.compButtonEnabled,
.compButtonEnabled:hover {
    background: gray;
}


.compButton {
    width:auto;
    height: 40px;
    outline: none;
    color:#ffffff ;
    background-color: gray;
    opacity: 0.7;
    border:2px solid gray;
    font-size: 16px;
    font-weight: 500;
    cursor: not-allowed;
}
.compButton,
.compButton:hover {
    background: gray;
    cursor: not-allowed;
}