@import "../../../../assets/styles/fonttypography";

.bglight {
  background-color: #f7f7f7;
}

.bgsuccess {
  background-color: #3498DB;
}

@media (max-width: 576px) {
  .top_header h6 {
    font-size: 13px;
  }
  .set_order_0 {
    order: 1;
  }
  .top_head {
    display: block !important;

    p {
      margin-top: 5px !important;
      font-size: 12px !important;
    }
  }
}

.top_head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 14px;
  }
}

.icon_wrap {
  width: 50px;
  line-height: 50px;
  height: 50px;

  i {
    font-size: 25px
  }
}

.review_btn {
  padding: 5px 20px;
  background-color: #cceaff;
  color: #3498DB;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  transition: .3s all;
}

.review_btn:hover {
  background-color: #3498DB;
  color: white;
}

.topic_list li h6 {
  font-size: 15px;
}

.topic_list .list_name {
  font-size: 13px;
  font-weight: 600;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topic_list li:nth-child(even) {
  background: #f4f4f4;
}

.trophy {
  background-color: #0F9D571A;

  h6 {
    font-size: 15px;
    font-weight: 600;
  }

  .icon_wrap {
    background-color: #0F9D57;
  }

  .active {
    color: #27AE60;
  }
}

.completeQuestion {
  background-color: #F67C551A;

  h6 {
    font-size: 15px;
    font-weight: 600;
  }

  .icon_wrap {
    background-color: #F67C55;
  }

  .active {
    color: #F67C55;
  }
}

.trophy, .completeAssignment, .completeQuestion {
  margin-bottom: 10px;
  padding: 10px 15px;
}

.trophy, .completeAssignment, .completeQuestion {
  .active {
    font-size: 15px;
  }
}

.completeAssignment {
  background-color: #114C741A;

  h6 {
    font-size: 15px;
    font-weight: 600;
  }

  .icon_wrap {
    background-color: #114C74;
  }

  .active {
    color: #114C74;
  }
}

.student_dashboard {
  .card-content-wrap {
    .due_date {
      .bg-dueDate {
        background-color: #fff1d8 !important;
        font-weight: 500;
      }

      .text-dueDate {
        color: orange;
      }
    }

    .fs-11 {
      font-size: 11px;
    }

    .card_head {
      font-size: 13px;

      .teacher_name {
        color: #27AE60;
      }

      .subject {
        color: deepskyblue;
      }
    }

    .assignment_info {
      .btn-add {
        font-size: 13px;

      }
    }

    .left_days {
      color: #9c9c9c;
      font-size: 11px;
    }
  }


  .bg-comman {
    background-color: #fff;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 0 31px 3px rgba(44, 50, 63, .02);

    .card-body {
      position: relative;
      padding: 25px;

      .db-info h3 {
        font-size: 22px;
        font-weight: 600;
        color: #000;
        margin-bottom: 0;
      }
    }
  }
}

.grid_4 {
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px;
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.top_head_card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  padding: 15px 10px 85px 10px;
  color: white;
}
.top_head_card{
  svg{
    z-index: 9;
  }
}

.question-answered {
  background: #63D12F;
}

.time-spend {
  background: #F88659;
}

.topic-learned {
  background: #20B2AA;
}

.reward-earned {
  background: #9370DB;
}

.notice {
  width: 4px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  opacity: var(--sds-size-stroke-border);
  background: #3498DB;
}

.ViewNowBtn {
  border: 1px solid white;
  outline: 1px solid white;
}
