.checkbox-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  vertical-align: middle;
  background-color: white;
  cursor: pointer;
}

.checkbox-style:checked::before {
  content: '\2714'; /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #3498db; /* Color for the checkmark */
}


.questionButton,.formulaButton {
  border:none;
  font-size: 18px;
  background-color: white;
  cursor: pointer;
}

.methodButtons {
  color:#3498DB;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  cursor: pointer;
  border-bottom-color: #3498DB;
  //transition:border-color 10000000000s;
}
.btnNewAdd,.btnNewAdd:hover,.btnAdd,.btnAdd:hover{
  width:auto;
  height: 39px;
  outline: none;
  color:#ffffff ;
  background-color: #3498DB;
  border:2px solid #3498DB;
  font-size: 15px;
  font-weight: 500;
}
.btnAdd,.btnAdd:hover {
  border-radius: 30px;
}
.btnCreateSubmit {
  width:100px;
  height: 40px;
  outline: none;
  color: white;
  background-color: #3498DB;
  border:2px solid #3498DB;
  border-radius: 5px;
}
.btnCreateSubmit:hover {
  width:100px;
  height: 40px;
  outline: none;
  color: white;
  background-color: #3498DB;
  border:2px solid #3498DB;
  border-radius: 5px;
  cursor: not-allowed;
}

.createQuestionButton {
  background:#3498DB!important;
}
.createQuestionButton,.createQuestionButton:hover{
  background: #3498DB!important;
}
