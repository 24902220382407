.timeContainer {
  background-color: rgba(234, 113, 65, 0.11);
  margin-bottom: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .timer {
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    .timeLeftNumber i{
      display: none;
    }
    .timeLeft,.timeLeftNumber {
      font-weight: bold;
    }

  }
}
.mob_only{
  display: none !important;
  @media (max-width: 576px) {
    display: flex !important;
    .timer-Left{
      height: 40px;
      width: 40px;
    }
  }
}
.desk_only{
  display: block !important;
  @media (max-width: 576px) {
    display: none !important;
  }
}
.timer-header{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #E56E40;
  padding: 3px 10px;
  color: white;
  text-align: center;
  border-bottom: 3px solid #833B1E;
}
.timer-Left{
  background-color: rgb(255, 255, 255);
  color: #FF7037;
  padding-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  height: 60px;
  width: 60px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 576px) {
  .timeContainer{
    .timer{
      padding: 5px 10px;
      .timeLeft{
        display: none;
      }
      .timeLeftNumber{
        display: flex;
        i{
          display: block;
        }
      }
    }
  }
}