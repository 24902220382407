.correct_icon{
  width: 20px;
  height: 20px;
  background-color: green;
  text-align: center;
  border-radius: 50%;
  line-height: 22px;
  i{
    color: white;
    font-size: 15px;
  }
}