$primary: #ED3F36;
$danger: #F44336;
$success: #8BC34A;
$warning: #FFC107;
$light: #FAFBFF;
$white: #ffffff;
$black: #404040;
$text-color: #919191;
$input-bg: #F2F2F2;
$input-border-color: none;
$input-placeholder-color: #888;
$form-label-margin-bottom: 6px;
$input-padding-y: 0.6rem;
$form-label-font-size: 16px;
$theme-color: 'dark';
$theme-colors: (
        primary: $primary,
        light: $light,
        danger: $danger,
        success: $success,
        warning: $warning,
);

.btn-primary {
  color: white !important;
}