.rank {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}

.leaderBoard{
    min-width: 180px;
}
.leader_board{
    .leader_rank{
        font-size: 16px;
    }
    .leader_name{
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 600;
    }
    .leader_points{
        font-size: 13px;
        font-weight: 400;
        color: #175883;
    }
}