.confirmationModalArea {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #0004;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1090;
  .confirmationModal {
    width: 40%;
    min-width: 420px;
    max-height: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
    overflow: auto;
    box-shadow: #0005 0 0 20px 0;

    .dialogHeader {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      .dialogCloseButton {
        padding: 0.4rem 1rem;
        cursor: pointer;
      }
    }
    .dialogBody {

      padding: 0.5rem 1rem;
      text-align: left;
      p {
        margin: 0;
      }
    }
    .dialogFooter {
      border-top: 1px solid #EEE;
      display: flex;
      justify-content: end;
      padding: 0.5rem 1rem;
    }
  }
}