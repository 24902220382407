.chapters_wrap {
  .mb-6 {
    margin-bottom: 4rem;
  }

  .multi-selector {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
  }

  .checkbox.style-b {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox.style-b input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 576px) {
      display: block;
      .wrap_c {
        margin-top: 10px;
      }
    }
  }

  .checkbox.style-b input:checked ~ .checkbox__checkmark {
    background-color: #fff;
    border-color: #3498DB;
  }

  .checkbox.style-b input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
    border-color: #3498DB;
  }

  .checkbox.style-b:hover input ~ .checkbox__checkmark {
    background-color: #eee;
  }

  .checkbox.style-b:hover input:checked ~ .checkbox__checkmark {
    background-color: #fff;
  }

  .checkbox.style-b .checkbox__checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #333;
    transition: background-color 0.25s ease;
    border-radius: 4px;
  }

  .checkbox.style-b .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .checkbox.style-b .checkbox__body {
    color: #333;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 700;
  }

  .chapter-item {
    cursor: pointer;
    position: relative;
    width: 100%;
    border-radius: 5px;
    background: #fff;
    border-left: 2px solid #fff;
  }


  //.topic-item {
  //  margin: 15px 0;
  //  font-size: 14px;
  //}

  .selected-count {
    margin-top: 20px;
    font-weight: bold;
  }

  .inputCheckbox {
    background-color: #F3F3F3;
  }

  .topicCount {
    width: 25px;
    height: 25px;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: rgb(33, 150, 243);
    color: #fff;
  }

  .btnGreen {
    background-color: #27ae60;
    padding: 10px 30px;
  }

  @media (max-width: 576px) {
    .sticky-bottom-btns {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 9;
      box-shadow: 3px 3px 5px 6px #ccc;
      padding: 15px 0;
    }
    .chip_w {
      justify-content: end;
    }
  }


}


/* Chapter wrapper to manage position */
.chapter-wrapper {
  position: relative;
}

/* Chapter item styles - this is the main trigger */
.chapter-item {
  cursor: pointer;
  padding: 12px 16px;
  background-color: #ffffff; /* White background for the chapter item */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-left: 4px solid #3498DB; /* Blue accent on the left to differentiate */
}

/* On hover, add interactivity to the chapter item */
.chapter-item:hover {
  background-color: #e9f5ff; /* Light blue hover background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly more prominent shadow */
  border-left-color: #3498DB; /* Darker blue accent on hover */
}

/* The dropdown (topic-list) is positioned absolutely below the chapter item */
.topic-list {
  position: absolute;
  top: 100%; /* Align just below the chapter item */
  left: 0;
  right: 0;
  margin: 0;
  background-color: #f9f9f9; /* Light gray background to differentiate from the chapter item */
  border-radius: 8px;
  border: 1px solid #ddd; /* Light gray border */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* More prominent shadow for dropdown */
  z-index: 6;
  display: none; /* Hidden by default */
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  overflow: hidden;
  max-height: 200px; /* Set your desired fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-left: 0; /* Adjust padding/margins as needed */
  list-style-type: none; /* Removes default bullet points */
}

/* When expanded, display the dropdown */
.chapter-wrapper.expanded .topic-list {
  display: block;
  max-height: 400px; /* Adjust the height as necessary */
  opacity: 1;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

/* Topic items styling inside the dropdown */
.topic-item {
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  background-color: #ffffff; /* White background for individual topics */
  border-bottom: 1px solid #e9ecef; /* Light gray border between topics */
  transition: background-color 0.2s ease;
}

/* Last topic item should not have a bottom border */
.topic-item:last-child {
  border-bottom: none;
}

/* Hover effect for topic items */
.topic-item:hover {
  background-color: #f1f8ff; /* Light blue background on hover for topics */
  border-radius: 4px; /* Slight rounding on hover */
}

/* Checkbox styling for topic items */
.inputCheckbox {
  margin-right: 10px;
}

/* The chevron icon behaves like a dropdown control */
.chevron-container {
  cursor: pointer;
}

/* Disabled chapter styles */
.disabled-chapter {
  background-color: #f8f9fa;
  color: #ccc;
}

.disabled-label {
  color: #aaa;
}

/* Styling for when a chapter is expanded */
.active-chapter {
  background-color: #e9f5ff; /* Light blue background when expanded */
  border-left-color: #0056b3; /* Darker blue accent for the expanded state */
}

/* Styling the count badge on the right */
.topicCount {
  background-color: #007bff;
  color: white;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 12px;
}

/* Media query for mobile devices */
@media (max-width: 576px) {
  .chapter-item {
    padding: 0.8rem;

    &:hover {
      background-color: white;
    }
  }

  .topic-list {
    padding: 8px;
    position: relative;
  }
}
