@media (max-width: 576px) {
    .onMobile_time {
        display: block;
    }
    .chapterHead h5{
        font-size: 15px;
    }
    .Stepper {
       margin: 10px 0 85px 0;
    }
    .Stepper_quiz{
        display: none;
    }

    .timeMobile {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-bottom: 10px;

        .instr button {
            display: none;
        }

        .instr i {
            margin-left: 10px;
        }
    }

    .questionContainer {
        width: 100%;
        height: 100%;
    }

    .btnContainer {
        margin-right: 1rem;
    }
}

@media (min-width: 576px) {
    .timeMobile {
        display: flex;
        align-items: center;
        justify-content: end;

        .instr i {
            display: none;
        }
    }

    .onMobile_time {
        display: none;
    }
}

.btnContainer {
    margin-top: 60px;
}

.offcanvas.offcanvas-bottom {
    height: 80vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.offcanvas .offcanvas-body .instruction-wrap {
    .sticky-bottom-btns {
        display: none;
    }

    .margns {
        margin-bottom: 10px;
    }
}

.offcanvas .offcanvas-body .btnContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 9;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 15px;
    padding: 15px 0;
}