@media (max-width: 576px) {
  .reviewStepper{
    display: none !important;
  }
}

.ExitBtn{
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #27ae60;
  color: #27ae60;
}
.ExitBtn:hover {
  border: 1px solid #27ae60;
  color: #27ae60;
}

.exit-display{
  background-color: white;
  padding: 10px;
}