.main {
    width: 80%;
}

.searchBar .formControl {
    padding-left: 2.375rem;
}

.formControl {
    width:220px;
    background-color: white;
}

.searchBar .formFeedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.5rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.questionImage{
    width:50px;
    height: 40px;

}
/* Add this to your CSS file */
.selected {
    color: rgb(128, 128, 128); /* Change this to the desired color */
}

/* Style for the checkbox itself */
.selected input[type="checkbox"] {
    /* Style for the checkbox itself when selected */
    background-color: rgb(128, 128, 128); /* Change this to the desired background color */
    border-color: rgb(128, 128, 128); /* Change this to the desired border color */
}
