.filterDropdown {
  z-index: 1000;
  min-width: 20rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  position: absolute;
  margin: 0px;
  display: block;
  right: 0;
  top: 0;
  transform: translate(-15px, -20px);

  .card-header {
    span {
      font-size: 14px;
      color: #3a90f5;
      font-weight: 700;
    }
  }

  .card-body {
    .filter_item {
      padding: 10px 0;

      span:first-child {
        font-size: 14px;
        font-weight: 500;
        color: #777777;
      }

      ul li {
        padding: 10px 0;
      }

      .border-bottom {
        border-bottom: 1px solid #dedede;
      }
    }
  }
}

.btnRemove {
  width: auto;
  height: 40px;
  outline: none;
  color: #3498DB;
  background-color: white;
  border: 2px solid #3498DB;
  border-radius: 5px;
  cursor: not-allowed;
  transition: .3s all;
}

.btnDisable {
  width: auto;
  height: 40px;
  outline: none;
  color: #3498DB;
  background-color: white;
  border: 2px solid #3498DB;
  border-radius: 5px;
  cursor: not-allowed;
  transition: .3s all;
  opacity: 0.6;
}

.btnRemove:hover,.btnSubmit:hover {
  background-color: #3498DB;
  color: white;
}

.btnSubmit {
  width: auto;
  height: 40px;
  outline: none;
  background-color: #06b48a;
  color: white;
  border: 2px solid #06b48a;
  border-radius: 5px;
  cursor: not-allowed;
  transition: .3s all;
  //opacity: 0.6;
}
.loading-option {
  display: flex;
  align-items: center;
  justify-content: center;
  color:#0878DF;
}