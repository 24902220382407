.page-wrapper{
  //@media (max-width: 576px) {
  //  margin-top: 72px;
  //  //height: 100%;
  //}
  background-color: #F4F4F4;
  //@media (min-width: 576px) {
  //  height: 100%;
  //}

  .courses_head{
    @media (max-width: 576px) {
      justify-content: space-between;
    }
    background-color: rgb(52 152 219 / 9%);
    color: #3498db;
  }
  @media (max-width: 576px) {
    .log_out .btn{
      display: none !important;
    }
  }
  .studentTab,.formulaButton {
    border:none;
    font-size: 18px;
    cursor: pointer;
  }
  .tabTextColor {
    color: rgb(158,158,158);
  }
  .methodButtons {
    color:#3498DB;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    cursor: pointer;
    border-bottom-color: #3498DB;
    //transition:border-color 10000000000s;
  }
}

.courseDashboardImage {
  position: relative;
  width: 100%;

}
.dashboard-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 152, 219, 0.15);
  z-index: 1;
}
.dashboard-search-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  max-width: 600px;
  padding: 2rem;
  text-align: center;
}

//.explore-course {
//  width:auto;
//  height: 35px;
//  font-size: 15px;
//  border:1px solid #3498DB;
//  color: white;
//  background-color: #3498DB;
//  cursor: pointer;
//}
//.buyNowForFreeTrail:hover {
//  color: white; /* Ensures text color remains the same */
//  background-color: #3498DB; /* Ensures background color remains the same */
//  border: 1px solid #3498DB; /* Ensures border color remains the same */
//}