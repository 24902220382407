.enrolled_wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    display: block;
  }
  .chip{
    padding: 8px 20px;
  }
}