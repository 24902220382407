.page-content {
  padding: calc(0px + 2rem) 0.75rem 60px 1.5rem;
}
.fs-14{
  font-size: 14px;
}
.page-titles {
  padding: 0.9375rem 1.25rem;
  padding-top: 0;
}
table.studentTable tbody tr:first-child td {
  border-top: none;
}
table.studentTable thead tr th{
  background-color: whitesmoke;
  font-size: 14px;
}
table.studentTable tbody td {
  padding: 12px 10px;
  font-size: 14px;
}
.btn-add{
  background-color: #3498DB !important;
  border-color: #3498DB !important;
}

.searchBar .formFeedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.4rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.searchBar {
  width: 25rem;

  button {
    color: #898989;
    font-size: 17px;
    height: 1rem;
    left: 0.625rem;
    margin-left: 10px;
    position: absolute;
    transform: translateY(-50%);
    width: 1rem;
    line-height: 1;
    margin-top: 1px;
    right: 20px;
    top: 50%;
  }
  .formControl {
    line-height: 1;
    font-size: 15px;
    border-radius: 5px;
    padding: 12px 40px;
    background: white;
    border: 1px solid #dedede;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
    &:focus{
      border-color:  #3498DB;
      box-shadow: none;
    }
  }
}
