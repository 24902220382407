.indicatorContainer
{
  border:1px solid #E3E3E3;
  border-top: 0;
  background-color: rgba(29, 168, 29, 0.06);
  padding: 15px;
  .indicator-step {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      border-radius:50%;
      margin-right:8px;
    }
  .notAnswered {
    background-color: #FFA337;
    color: #fff;

  }
  .bookMark
  {
    background-color: #9370DB;
    color: #fff;

  }
  .answered{
    background-color: rgb(52, 152, 219);
    color: #fff;

  }
.indicator_label{
  font-size: 13px;
  font-weight: 500;
}
}
.grid_auto{
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px
}