.chips {
  @media (max-width: 576px) {
    margin-top: 10px;
    justify-content: end;

  }

  .chip {
    cursor: pointer;
    padding: 8px 30px;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    background-color: #F5F5F5; /* Grey background color for inactive chips */
    color: #333; /* Grey text color for inactive chips */
  }

  .chip.active {
    background-color: #9FCBD4;
    color: #fff;
  }

  .checkbox {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    border: 1px solid #9c9c9c;
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }

  .checkbox__input {
    position: absolute;
    top: -100px;
    left: -100px;
  }

  .checkbox__body {
    color: #333;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 700;
  }

  .checkbox__inner {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid #9c9c9c;
    background: transparent no-repeat center;
  }

  .checkbox__input:checked + .checkbox__inner {
    border-color: #3498DB;
    background-color: #3498DB;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='10px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 14px 10px;
  }
}
