.teacherimg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 50px;
    line-height: 150px;
}
.actions a:hover {
    background-color: #3498DB !important;
    color: #fff!important;
}
.bg-success-light {
    background-color: #f7f7fa!important;
    border-radius: 50px;
}
.actions a {
    width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}