
.scoreContainer {
  background-color: #ECF5F6;
  width: 100%;
  padding: 10px 10px;

  img {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .scoreBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 90px
  }
}

.chapterHeader {
  .btn-review {
    padding: 5px 20px;
    background-color: #3498DB;
    color: white;
    font-size: 14px;
  }
}

.pieChartContainer {
  border: 1px solid #F8F8F8;
  border-radius: 4px;
  display: flex;

  @media (max-width: 576px) {
    display: block;
  }

  .pieChart {
    height: 350px;
    padding: 15px 20px 15px 80px;
    margin: 15px 85px;
    margin-right: 0;
    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
      height: auto;
      padding: 0;
      margin: 15px 85px;
    }
  }

  .resultIcons {
    padding: 160px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 576px) {
      padding: 0;
      margin: 0 auto;
    }

    .status-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 auto;
    }

    .correct {
      background-color: #4ECB71;
    }

    .wrong {
      background-color: #FF9A62;
    }

    .skipped {
      background-color: #85B6FF;
    }

  }

}
