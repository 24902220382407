#header {
  padding-left: 300px;
  z-index: 10 !important;
  @media (max-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0 !important;
  }
}

.headerCollapsed {
  padding-left: 100px !important;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 1.5rem 0 calc(1.5rem * .5);
}

.teacherimage {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 9999; /* Make sure it's above other elements */
}

@media (max-width: 576px) {
  #sidebarToggle {
    pointer-events: auto;
  }
}

//@media (min-width: 1440px) {
//  #sidebarToggle {
//    pointer-events: none;
//  }
//}

#sidebarToggle {
  align-items: center;
  height: 40px;
  width: 40px;
}

#sidebarToggle span {
  background-color: #151d2e;
  border-radius: 3px;
  display: block;
  height: 2px;
  position: relative;
  transition: all .2s ease-in-out;
  width: 15px;
}

#sidebarToggle span:before {
  background-color: #151d2e;
  border-radius: 3px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all .2s ease-in-out;
  width: 20px;
  top: -6px;
}

#sidebarToggle span:after {
  background-color: #151d2e;
  border-radius: 3px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all .2s ease-in-out;
  width: 20px;
  bottom: -6px;
}

#sidebarToggle:hover span {
  width: 20px;
}

#sidebarToggle:hover span:before {
  width: 15px;
}

#sidebarToggle:hover span:after {
  width: 15px;
}