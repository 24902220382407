.toast-container-main {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    width: 350px !important;
}

.toast-container-main-failed {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    width: 350px !important;
}

.toast-container-main > div {
    background-color: white;
    color: white;
    width: 100%;
    margin-bottom: 10px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: slide-in 0.2s;
    transition: all 0.3s ease;
    padding: 20px 15px 20px 20px;
    border-radius: 10px;
    border-left: 5px solid #5a8d5f;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.15);
}

.toast-container-main-failed > div {
    background-color: white;
    color: white;
    width: 100%;
    margin-bottom: 10px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: slide-in 0.2s;
    transition: all 0.3s ease;
    padding: 20px 15px 20px 20px;
    border-radius: 10px;
    border-left: 5px solid #f11414;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.15);
}

.toast-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.toast-header-failed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.toast-header .success-icon{
    font-size: 17px;
    color: #fff;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 50%;
    background: #5a8d5f;
}
.toast-header-failed .success-icon{
    font-size: 17px;
    color: #fff;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 50%;
    background: #fd1c1c;
}
.toast-header .close-icon {
    color: #878787;
    font-size: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    background: #f2f2f2;
    transition: all 0.3s ease;
}
.toast-content {
    color: #878787;
    font-size: 15px;
}
.toast-header-failed .close-icon {
    color: #878787;
    font-size: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    background: #f2f2f2;
    transition: all 0.3s ease;
}
.close-icon button{
    color: #878787;
}
@keyframes slide-in {
    from {
        transform: translateX(30px);
        opacity: 0;
    }
    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

button {
    background: none;
    border: none;
    /*color: #ffffff;*/
    font-weight: 900;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 4px 8px;
}

button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.viewTask
{
    text-decoration: none;
}