.topicHeader {
  padding: 15px 4px;
}

.wrapper_c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    display: block;
    .wrap_c {
      margin-top: 10px;
    }
  }
}

.btnGreen, .btnGreen:hover {
  background-color: #27ae60;
  padding: 10px 30px;
  color: white;
}

.chip_w {
  display: flex;
}

@media (max-width: 576px) {
  .sticky-bottom-btns {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 9;
    box-shadow: 3px 3px 5px 6px #ccc;
    padding: 15px 0;
  }
  .chip_w {
    display: block;
    justify-content: end;
  }
}
