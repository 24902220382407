.tableContainer
{
  td{
    border-right: 1px solid #ECECEC;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
  }

  th
  {
    font-size: 13px;
    border-right: 1px solid #ECECEC;
  }

}
.btnCancel{
  border: 1px solid #a1a1a1;
  color: #a1a1a1;
  padding: 10px 30px;
}
.btnSuccess,.btnSuccess:hover,.btnSuccess:focus{
  background-color: #27ae60;
  padding: 10px 30px;
  color: white;
}