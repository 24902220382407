@import "../../../../../assets/css/style";

.thr_grid {
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .student-course-card {
    margin-top: 10px;
  }
}

.student-course-card {

  .card {
    border-radius: 15px;
    display: block;
    background-color: #fff;
    position: relative;

    .left_days {
      width: 90px;
      height: 23px;
      position: relative;
      text-align: center;

      label {
        color: #DB7A34;
        font-size: 12px;
      }

      &:before {
        content: "";
        position: absolute;
        right: -15px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 15px solid #FFE0CF;
        border-top: 12px solid transparent;
        border-bottom: 11px solid transparent;
      }
    }
    .days_left{
      background: #FFE0CF;
    }
    .expired{
      background: #ffcfcf;
      label{
        color: #ff4a4a;
      }
      &:before{
        border-left-color: #ffcfcf;
      }
    }

    .card-body {
      .dropdown-toggle::after {
        display: none;
      }


    }

    .course_programs {
      i {
        color: #3498DB;
      }
    }

    .res_btn {
      .btn-res {
        padding: 5px 10px;
        background-color: #3498DB;
        color: white;
        font-size: 14px;
      }
    }
    .btn-reactivate{
      padding: 5px 10px;
      color: black;
      font-size: 14px;
      font-weight: 600;
      background-color: #dddddd;
    }
    .buyNowForFreeTrail {
      width:auto;
      height: 35px;
      font-size: 14px;
      border:1px solid #3498DB;
      color: white;
      background-color: #3498DB;
      cursor: pointer;
    }
  }
}
