.submitEarlyBtn {
    width: 100%;
    height: 50px;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid #3498DB;
    color: #3498DB;
}
.submitEarlyBtn:hover {
    border: 1px solid #3498DB;
    color: #3498DB;
}
.submitEarlyBtn.active {
    background-color: #27ae60;
    color: white;
    border: none;
}

.submitEarlyBtn.active:hover {
    background-color: #07813b;
    color: white;
    border: none;
}