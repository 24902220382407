/*!* ReportDropdown.css *!*/

/*!* Container for the entire component *!*/
/*.report-dropdown-container {*/
/*    position: relative;*/
/*}*/

/*!* Dropdown container *!*/
/*.dropdown {*/
/*    position: absolute;*/
/*    bottom: 0; !* Display the dropdown at the bottom *!*/
/*    left: 0;*/
/*    z-index: 1;*/
/*}*/

/*!* Dropdown list *!*/
/*.dropdown-list {*/
/*    list-style: none;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*    width: 250px;*/
/*}*/

/*!* Dropdown item *!*/
/*.dropdown-item {*/
/*    padding: 8px 16px;*/
/*    cursor: pointer;*/
/*    border-bottom: 1px solid #ccc;*/
/*}*/
/*!* ReportDropdown.css *!*/

/*!* Container for the entire component *!*/
/*.report-dropdown-container {*/
/*    position: relative;*/
/*}*/

/*!* Dropdown container *!*/
/*.dropdown {*/
/*    position: absolute;*/
/*    bottom: 0; !* Display the dropdown at the bottom *!*/
/*    left: 0;*/
/*    z-index: 1;*/
/*}*/

/*!* Dropdown list *!*/
/*.dropdown-list {*/
/*    list-style: none;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*    width: 250px;*/
/*}*/

/*!* Dropdown item *!*/
/*.dropdown-item {*/
/*    padding: 8px 16px;*/
/*    cursor: pointer;*/
/*    border-bottom: 1px solid #ccc;*/
/*}*/
