.parent{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
}
.lock-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: #F3F9FD;
    border-radius: 50%;
    border: 1px solid #BFDFF4;
}
.icon{
    color: #3498DB;
    font-size: 35px;
}
.icon-text{
    font-size: 22px;
    margin-top: 5px;
    color: #737373;
    font-weight: 600;
}
.description{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        padding: 0;
        margin: 1px;
    }
}
.buy-button,.buy-button:hover{
    padding: 8px;
    margin: 25px;
    width: 80%;
    height: auto;
    background-color: #3498DB;
    color: white;
}
