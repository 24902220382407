.topics_listing {
  &.gr_list li:before {
    background-color: #93DB8C;
  }

  &.re_list li:before {
    background-color: #F47C7C;
  }

  ul li {
    list-style: none;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    &::before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      display: inline-grid;
      margin-right: 15px;
    }
  }
}
.mob_placement{
  display: flex;
  @media (max-width: 576px) {
    display: block;
  }
}
.scoreBox{
  h6,p{
    font-size: 14px;
    font-weight: 600;
  }
  .bg_gr{
    background-color: #E8FFF1;
  }
  .bg_or{
    background-color: #FFF0D9;
  }
  .bg_lg{
    background-color: #F4F4F4;
  }
}
.analysisCard{
  background-color: #ffffff;
}
.analysis-card-body{
  background-color: rgba(217, 242, 255, 0.32);

}
