@import "../../../../../assets/css/style";

.thr_grid {
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .student-course-card {
    margin-top: 10px;
    transition: transform 0.3s ease;
  }

  .student-course-card:hover {
    transform: scale(1.03);
  }
  .image-overlay {
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(52, 152, 219, 0.15);
    z-index: 1;
    display: none;
  }

  .student-course-card:hover .image-overlay{
    display: block;

  }
  .course-image{

  }
  .play-button{
    position: absolute;
    background-color: rgba(128, 128, 128, 0.84);
    border-radius: 50%;
    padding: 7px;
    //opacity: 0;
  }
  //.student-course-card:hover .play-button {
  //  opacity: 1;
  //}


  .renew-button{
    position: absolute;
    background-color:#3498DB;
    border-radius: 10px;
    color:white;
    padding: 7px;
    //opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .trial-button {
    position: absolute;
    top: 10px;
    right: 5px;
    background: #28B728;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 12px;
  }

  //.student-course-card:hover .renew-button {
  //  opacity: 1;
  //
  //}


}
.btn-explore,.btn-explore:hover{
  background-color: #27AE60;
  color: white;
}
.student-course-cards {

  .card {
    border-radius: 15px;
    display: block;
    background-color: #fff;
    position: relative;


    .card-body {
      .crd_date{
        font-size: 13px;
        font-weight: 600;
      }
      .card-description {
        font-size: 13px;
        color: $text-color;
        z-index: 2;
        position: relative;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .course_programs {
        font-size: 14px;
        font-weight: 600;
        i {
          color: #3498DB;
        }
      }

      .res_btn {
        .btn{
          padding: 5px 10px;
          border: 1px solid #3498DB;
          font-size: 14px;
        }
        .btn-strt{
          background-color: #3498DB;
          color: white;
        }
        .btn-res {
          background-color: transparent;
          color: #3498DB;
        }
      }
      .freeTrailButton {
        width:auto;
        height: 35px;
        font-size: 14px;
        outline: none;
        color: #3498DB;
        background-color: white;
        border:1px solid #3498DB;
        border-radius: 5px;
        cursor: pointer;
        transition: .3s all;
      }
      .buyNowButton {
        width:auto;
        height: 35px;
        font-size: 14px;
        border:1px solid #3498DB;
        color: white;
        background-color: #3498DB;
        cursor: pointer;
      }
    }

  }



}

.explore-course-card {
  margin-top: 10px;
  transition: transform 0.3s ease;
}

.explore-course-card:hover {
  transform: scale(1.03);
}
